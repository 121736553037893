.staff-workflow {
  width: 100vw;
  height: 93vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 1%;
}

.staff-workflow-container {
  width: 100vw;
  height: 100vh;
  background-color: white;
  display: flex;
  flex-direction: column;
}

.staff-workflow-container .header {
  flex: 5%;
  display: inline-block;
  background-color: black;
  color: white;
}
.staff-workflow-container .header h1 {
  display: inline-block;
  justify-self: center;
  text-align: center;
}

.staff-workflow-container .body {
  padding-top: 5%;
  padding-left: 5%;
  padding-right: 5%;
  flex: 50%;
  font-weight: bold;
}

.staff-workflow-container .footer {
  flex: 30%;
  display: flex;
  justify-content: center;
}

.staff-workflow-container .header .signout {
  padding-top: 2%;
  margin-right: 2%;
  float: right;
}
