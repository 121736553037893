.form {
  width: 100vw;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 1%;
}

.form-container {
  width: 100vw;
  height: 100vh;
  background-color: white;
  display: flex;
  flex-direction: column;
}

.form-container .header {
  flex: 5%;
  display: grid;
  place-items: center;
  background-color: black;
  color: white;
}

.progressbar {
  width: 100vw;
  height: 1.5vh;
  background-color: white;
  margin-bottom: 5vh;
}

.progressbar div {
  width: 33.3%;
  height: 100%;
  background-color: rgb(13, 166, 161);
}

.form-container .body {
  flex: 50%;
  justify-items: center;
}

.form-container .body .prompt {
  font-weight: bold;
  padding-bottom: 5vh;
}

.form-container .body button {
  margin-left: 1%;
  margin-right: 1%;
}

.form-container .footer {
  justify-content: center;
}