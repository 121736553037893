.landing-page {
  width: 100vw;
  height: 93vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 1%;
}

.landing-page-container {
  width: 100vw;
  height: 100vh;
  background-color: white;
  display: flex;
  flex-direction: column;
}

.landing-page-container .header {
  flex: 5%;
  display: grid;
  place-items: center;
  background-color: black;
  color: white;
}

.landing-page-container .body {
  padding-top: 5%;
  padding-left: 5%;
  padding-right: 5%;
  flex: 50%;
  font-weight: bold;
}

.landing-page-container .footer {
  justify-content: center;
}
