.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.App-container {
  flex: 80%;
  display: grid;
  place-items: center;
}

.App-container button {
  margin-top: 3%;
}

.App-footer {
  flex: 20%;
  display: grid;
  place-items: center;
}

.App .error {
  color: #ab0f0f;
  font-weight: bold;
}

.logoStyle {
  height: 75px;
  padding: 2%;
}
